import React, { useState, useRef, useContext, createRef } from 'react'
import styled from 'styled-components'
import DownloadButton from '../components/global/DownloadButton'
import DottedLine from '../components/global/DottedLine'
import PageSubtitle from '../components/global/PageSubtitle'
import PageInfo from '../components/global/PageInfo'
import Seo from '../components/global/Seo'
import useWindowDimensions from '../hooks/useWindowDimensions';
import FavoritesContext from '../context/favorites';
import ScreenshotModeContext from '../context/screenshotMode';
import BackgroundImg from'../images/entwerfen/paperbackground-op.jpg'
import arrowIcon from '../images/icons/generieren/arrow.svg'
import FavoritesOverlay from '../components/entwerfen/FavoritesOverlay'

// Import Swiper React components
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import figurettenData from '../data/figurettenData'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import "swiper/components/effect-coverflow/effect-coverflow.min.css"
SwiperCore.use([Navigation, EffectCoverflow ]);


const CenterHelper = `
  pointer-events: none;
  margin: 0;
  position: absolute;
  top:55%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const CenterHelperMargin = `
  pointer-events: none;
  margin: 0;
  position: absolute;
  top:55%;
  left: calc(50% - 100px);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`


const NavButtons = `
  z-index:9999;
  position:absolute;
  width:50px;
  bottom:90px;
  height:20px;
  cursor: grab;
`

const WorkSpaceHeight = `height:75.5%;` 
const BackgroundHeight =  `height:70%;` 


const BackgroundTexture = styled.img`
  ${CenterHelperMargin}
  ${BackgroundHeight}
`

const DesignPageWrapper = styled.div`
  user-select: none;
  position:absolute;
  height: 100vh;
  width: 100vw;
  top:0px;
  background:white;
`


const DesignerWrapper = styled.div`
  margin:0 300px 0 100px;
`

const Overlay = styled.img`
  z-index:100;
  opacity:0.6;
  ${CenterHelperMargin}
  ${BackgroundHeight}
  display:${props => props.showOverlay ? 'block' : 'none'}; 
  `

const Drawing = styled.img`
  z-index:120;
  ${CenterHelper}
  ${WorkSpaceHeight}
`


const FiguretteWrapper = styled.div`
  opacity:${props => props.show ? '1' : '0.5'};
  display:${props => props.hide ? 'none' : 'block'};
  transition:all ease-in-out 0.3s;
  height:100vh;
`

const Silhouette = styled.img`
  ${CenterHelper}
  ${WorkSpaceHeight}
`

const DrawingOverlay = styled.img`
  ${CenterHelperMargin}
  ${WorkSpaceHeight}
  z-index:180;
  display:${props => props.showOverlay ? 'block' : 'none'}; 
`

const PrevArrow = styled.img`
  ${NavButtons}
  left:3vw;
  bottom:90px;
  &:hover{
    transform: scale(1.2);
    transition:all 0.05s ease-in-out;
  }
`
const NextArrow = styled.img`
  ${NavButtons}
  right:3vw;
  transform: rotate(-180deg);
  &:hover{
    transform: rotate(-180deg) scale(1.2);
    transition:all 0.05s ease-in-out;
  }
`

const BorderOverlay = styled.img`
  ${CenterHelperMargin}
  ${WorkSpaceHeight}
  z-index:160;
  display:${props => props.showOverlay ? 'block' : 'none'}; 
`

const DownloadWrapper = styled.div`
  z-index:499;
  position:absolute;
  right:10%;
  top:20%;
  width:350px;
  cursor:pointer;
`

const ScreenshotFooter = styled.div`
  position: absolute;
  right:50px;
  bottom:25px;
  z-index:999;
  h2{
    text-transform:uppercase;
    font-weight:700;
    font-family:"Avenir Next", sans-serif;
  }
  h3{
    font-family:"Avenir Next", sans-serif;
    font-size:1.2rem; 
    line-height:150%;
    font-weight: 300;
    text-transform:uppercase;
  }
  h4{
    font-family:"Avenir Next", sans-serif;
    font-weight: 300;
  }
`

const ScreenshotTitle = styled.h1`
  font-family:"Avenir Next", sans-serif;
  position: absolute;
  top:90px;
  z-index:99999999;
  text-transform:uppercase;
  margin-bottom:20px;
  margin-left:4vw; 
  padding-left:10px;
  padding-right:10px;
  background-color:white;
`


const Entwerfen = () => {
  const screenshotRef = createRef(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [showOverlay, setShowOverlay] = useState(true);
  const [linePositions, setLinePositions] = useState({start:{x:0,y:0},end:{x:0,y:0}});
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const { height, width } = useWindowDimensions();
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [droppedFav, setDroppedFav] = useState("");
  const { favorites } = useContext(FavoritesContext);
  const { screenshotMode,setScreenshotMode } = useContext(ScreenshotModeContext);
  const [pageInfo, setPageInfo] = useState({title:`Gestalte Dein eigenes Modedesign`,subtitle:`${figurettenData[0].title} (${figurettenData[0].year})`});
  
  
  function getTooltip(){
    if(favorites.length !== 0) return "Ziehe das Muster auf die Mode-Silhouette"
    if(favorites.length === 0) return "Sammle zuerst Deine Favoriten und gestalte dann Dein Design"
  }

  return (
    <>
      <Seo/>
      <PageSubtitle show={!screenshotMode ? "true" :"false"}>{pageInfo.title}</PageSubtitle>
      <PageInfo 
        title={`${screenshotMode ? "Auf: ": ""}${pageInfo.subtitle}`} 
        text={figurettenData[activeSlideIndex].infoBox.text}
        show="true"
        screenshotMode={screenshotMode}
      />
      <DesignPageWrapper  
        ref={screenshotRef}
        onDragOver={(e)=> {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e)=> {
          e.preventDefault();
          e.stopPropagation();
          const clicketFav= e.dataTransfer.getData("Text");
          const parsed = JSON.parse(clicketFav)
          parsed.position = {x:e.clientX-parsed.sizeX/4,y:e.clientY-parsed.sizeY/4}
          setDroppedFav(parsed)
        }}
      > 
        <FavoritesOverlay 
          onMouseEnterEvent={(e)=> {
            const {x,y} = e.target.getBoundingClientRect()   || 0
            const windowWidth = window?.innerWidth
            const windowHeight =  window?.innerHeight
            const newPositions = {start:{x:x-80,y:y+20},end:{x:windowWidth/2-100,y:windowHeight/2}}
            setLinePositions(newPositions)
            setDisplayTooltip(true)
          }}
          onMouseLeaveEvent={()=> {
            setDisplayTooltip(false)
          }}
          droppedFav={droppedFav}
        /> 
        <DesignerWrapper >
          <Swiper
            onSlideChangeTransitionEnd={(e) => {
              setShowOverlay(true)
            }}
            onSliderMove={() => {
              setShowOverlay(false)

            }}
            onSnapIndexChange={(e) => {
            
              setPageInfo({...pageInfo,subtitle:`${figurettenData[e.activeIndex].title} (${figurettenData[e.activeIndex].year})`})
              setActiveSlideIndex(e.activeIndex)
            }}
            onTransitionEnd={() => setShowOverlay(true)}
            autoHeight={true}
            effect={'coverflow'}
            centeredSlides= {true}
            spaceBetween={150}
            threshold={20} // to prevent bene from accidental swiping
            slidesPerView={2}
            allowTouchMove={true}
            coverflowEffect={{
              "rotate": 0, // Slide rotate in degrees
              "stretch": 0, // Stretch space between slides (in px)
              "depth": 700, // Depth offset in px (slides translate in Z axis)
              "modifier": 1, // Effect multipler
              slideShadows : false, // Enables slides shadows
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
          {figurettenData.map((figurette, index) => (
            
            <SwiperSlide key={index}>   
            
              <FiguretteWrapper show={activeSlideIndex === index} hide={screenshotMode}> 
                <Drawing src={figurette.drawing} draggable="false"/>     
                <Silhouette src={figurette.img}/>
              </FiguretteWrapper>
                
            </SwiperSlide>
          ))}
          
            <PrevArrow ref={navigationPrevRef} src={arrowIcon} onClick={()=> setShowOverlay(false)}></PrevArrow>
            <NextArrow ref={navigationNextRef} src={arrowIcon} onClick={()=> setShowOverlay(false)}></NextArrow>
          </Swiper>
          <Overlay src={figurettenData[activeSlideIndex].overlay} draggable="false" showOverlay={showOverlay}/> 
          <DrawingOverlay src={figurettenData[activeSlideIndex].drawing} showOverlay={showOverlay}/>
          <BorderOverlay src={figurettenData[activeSlideIndex].border} showOverlay={showOverlay}/>
          <BackgroundTexture src={BackgroundImg}  draggable="false"/> 
          {displayTooltip &&  
            <DottedLine
              start={linePositions.start}
              end={{x:width/2-100,y:height/2}}
              offset={-300}
              tooltipp={getTooltip()}
            />
          }
          <DownloadWrapper>
            {favorites.length !== 0 && !screenshotMode &&
              <DownloadButton onDownloadClick={() =>setScreenshotMode(true)}  text={"DIESES DESIGN HERUNTERLADEN?"}/>
            }
          </DownloadWrapper>
        </DesignerWrapper>
        {screenshotMode && <>
        <ScreenshotTitle>
          Dein Entwurf vom {new Date().toLocaleDateString("de",{ year: 'numeric', month: 'long', day: 'numeric' })}
        </ScreenshotTitle>
        <ScreenshotFooter>
          <h2>Calico</h2>
          <h3><i>Entdecken. Erleben. Entwerfen</i></h3>
          <br></br>
          <h4><i>calico.timbayern.de</i></h4>
        </ScreenshotFooter>
          </>
        }
      </DesignPageWrapper>
    </>
  )
}

export default Entwerfen
