import styled from "styled-components";
import FavoritesContext from "../../context/favorites";
import React, { useContext, useState, useEffect } from "react";
import ActivePattern from '../entwerfen/ActivePattern';
import {FavoriteItem} from '../global/Favorites';
import {FavoritesWrapper} from '../global/FavoritesWrapper'
import ScreenshotModeContext from '../../context/screenshotMode';
import ActivePatternContext from '../../context/activePattern';
import usePreloadFavoriteDragImages from '../../hooks/usePreloadFavoriteDragImages'
import { fullPatternImageUrl } from "../../data/dataUrls"

const FavoriteImgWrapper = styled.div`
  z-index:5000;
  user-select: none;   
  width: 100%; 
  height:100%;
  &:hover{
    cursor: grab;
  }
`

const FavoritesOverlay = ({ onMouseEnterEvent, onMouseLeaveEvent, droppedFav}) => {
  const { favorites } = useContext(FavoritesContext);
  const [mode, setMode] = useState("none")
  const [selectedPatternUID, setSelectedPatternUID] = useState("")
  const { activePatterns, setActivePatterns } = useContext(ActivePatternContext)
  const { screenshotMode } = useContext(ScreenshotModeContext);
  const dragImages = usePreloadFavoriteDragImages()


  useEffect(() => {
    window.addEventListener('keydown', handleDeletePress , { passive: false });
    return () => {
      // clean up the event handler when the component unmounts
      window.removeEventListener('keydown', handleDeletePress );
    };
  })

  useEffect(() => {
    const handleAddToActive = (clickedFavorite) => {
      clickedFavorite.uid = `${clickedFavorite.path}-${activePatterns.length}`
      clickedFavorite.scale = 1
      clickedFavorite.rotation = 0
      let tempActivePatterns = [...activePatterns,clickedFavorite]
      setActivePatterns(tempActivePatterns)
    }

    if(droppedFav !== "") {
      handleAddToActive(droppedFav)
    }
  },[droppedFav])
  
  const handleDeletePress = (e) => {
    const {key} = e
    if(key === 'Delete' || key ===  'Backspace') {
      const filtered = activePatterns.filter(item => item.uid !== selectedPatternUID)
      activePatterns.includes(selectedPatternUID)
      setActivePatterns(filtered)
    }
  }


  const handleDeleteClick = () => {
    const filtered = activePatterns.filter(item => item.uid !== selectedPatternUID)
    activePatterns.includes(selectedPatternUID)
    setActivePatterns(filtered)
  }

  useEffect(() => {
    const handlePointerUp = (e) => {
      setMode("none")
    }
    document.addEventListener('pointerup', handlePointerUp, { passive: true });
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('pointerup', handlePointerUp);
    };
  }, [mode])


  useEffect(() => {
    /* unselect Image if user clicks anywhere else */
    const handleClick = (e, index) => { 
      if(typeof(e.target.className) === 'string') { // check type to prevent failure
        !e.target.className.includes("ActivePattern")  && setSelectedPatternUID("")
      }
    };
    document.addEventListener('click', handleClick, { passive: true });
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('click', handleClick);
    };
  }, [selectedPatternUID])



  
  const handlePatternClick = (clickedPatternIndex) => {
    const clickedElem =activePatterns[clickedPatternIndex]
    setSelectedPatternUID(clickedElem.uid) // save which pattern is selected
    //put selected pattern to the end of array to render at least => render on top
    const tempActivePatterns = activePatterns.filter((elem,index) => index !== clickedPatternIndex)
    const all = [...tempActivePatterns, clickedElem] // add patternId to the end of array of active patterns 
    setActivePatterns(all)
  }

  const handlePositionChange = (x,y,index) => {
    const tempActivePatterns = activePatterns
    tempActivePatterns[index].position = {x,y}
    setActivePatterns(tempActivePatterns)
  }

  const handleTransformationChange = (scale, rotationDegree, index) => {
    const tempActivePatterns = activePatterns
    tempActivePatterns[index].scale = scale
    tempActivePatterns[index].rotation = rotationDegree
    setActivePatterns(tempActivePatterns)
  }

  return (
    <>  
      {activePatterns?.map((activePattern,index) => 
        <ActivePattern 
          key={activePattern.uid}
          mode={mode}
          onSetMode={(mode) => setMode(mode)}
          position={activePattern.position}
          selectedPattern={selectedPatternUID === activePattern.uid}
          onImagePointerDown={()=>handlePatternClick(index)}
          patternImage={`${activePattern.generated ? "":fullPatternImageUrl}${activePattern.path}`}
          size={{x:activePattern.sizeX/2,y:activePattern.sizeY/2}}
          onDeletePress={() => handleDeleteClick(activePattern)}
          onPositionChange={({x,y}) => handlePositionChange(x,y,index)}
          onTransformation={(scale,rotationDegree) => handleTransformationChange(scale,rotationDegree,index)}
          initialScale={activePattern.scale}
          initialRotation={activePattern.rotation}
        /> 
      )}
      {!screenshotMode && 
        <FavoritesWrapper zIndex={299} background={false}>
          {[...Array(12)].map((item, index) => 
            <FavoriteItem  key ={index}>
              <FavoriteImgWrapper
                draggable
                onMouseEnter={(e) => onMouseEnterEvent(e)} // to get position for dotted line 
                onMouseLeave={(e) => onMouseLeaveEvent(e)} // to get position for dotted line 
                onDragStart={(e) => { 
                  e.dataTransfer.setData('Text', JSON.stringify(favorites[index]));
                  e.dataTransfer.setDragImage(dragImages[index], 32, 32);
                }}
                onDragOver={(e)=> {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              ></FavoriteImgWrapper>
            </FavoriteItem>
          )}
        </FavoritesWrapper>
      }
    </>
  );
};

export default FavoritesOverlay;
